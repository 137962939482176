//library
import React, { ReactElement } from 'react';

/**
 * Create a 404 page
 *
 * @param {Error} error - The error message.
 * @returns {ReactElement} - The 404 page.
 */
const Custom404 = ({
    message = 'An unexpected error has occurred.'
}: {
    message?: string;
}): ReactElement => {
    return (
        <div className="text-center text-black bg-white h-screen flex flex-col justify-center items-center">
            <div>
                <h1 className="inline-block texrt-left border-r font-medium pr-1 text-5xl align-middle">
                    404
                </h1>
                <div className="pl-1 inline-block text-left leading-10 align-middle">
                    <h2>{message}</h2>
                </div>
            </div>
        </div>
    );
};
export default Custom404;
